import $, { data } from 'jquery';
import 'what-input';
import axios from 'axios';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

(function($, window, document) {
    $(function() {
        const path = "../";
        const APIPath = "https://api.quadrat.com.pe"; //"http://localhost:1337";
        let phpPath = "https://quadrat.com.pe/";
        let currMediaQuery = Foundation.MediaQuery.current;
        // const siteURL = "https://quadrat.com.pe";
        const apiProduction = "https://api.quadrat.com.pe";

        let vidPath = path;
        // let videoDsk = "assets/media/quadrat-web.mp4";
        // let videoMob = "assets/media/quadrat-m.mp4";
        
        // const cors = require('cors');
        let sperantProjID = "";
        let projectApartmentData = [];
        let sperant_depa_data = [];
                
        /* Checking the pathname of the URL and then calling the corresponding function. */
        
        // TODO: review 
        
        /**
         * This function returns the URL parameters from the current URL
         * @returns The URL parameters.
         */
        const returnURLParams = () => {
            let param = "";
            let queryString = window.location.search;
            const urlSrchParams = new URLSearchParams(queryString);
            for (let p of urlSrchParams) {
                param = p[0];
            };

            return param;
        };
            
        const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Dic"];
        
        
        /**
         * It gets the data from the API and populates the HTML with the data.
         */
        const getHome = () => {
            // INDEX Video
            $.getJSON(apiProduction + "/api/indx-video?populate=*", (data)=> {
                let rawData = data;
                let onVideo = rawData.data;
                // console.log(onVideo.attributes.video_movil.data.attributes.url);
                let videoMob = onVideo.attributes.video_movil.data.attributes.url;
                let videoDsk = onVideo.attributes.video_desktop.data.attributes.url;
                
                if(currMediaQuery === "small"){
                    setVideo(videoMob);
                }else{
                    setVideo(videoDsk);
                }    
                // videoDsk = "quadrat-web.mp4";
                // videoMob = "quadrat-m.mp4";
            }).done(function() {
                console.log( "donde: second success" );

            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });


            // INDEX PROY OnSale
            $.getJSON(apiProduction + "/api/proyectos?filters[enventa][$eq]=true&populate=*", (data) => {
                console.log( `ONSALE DATA Proyecto / ${PROJECT_NAME}` );    
                let rawData = data;
                let onSale = rawData.data;
                // console.log({onSale});
                
                let pName = onSale[0].attributes.nombre
                pName = pName.toUpperCase();
                $('[data-strapi-mp-alert]').html(onSale[0].attributes.py_alerta);
                $('[data-strapi-mp-image]').html(`<img src="${APIPath}${onSale[0].attributes.imagen_galeria.data.attributes.url}"></img>`);
                $('[data-strapi-mp-pinfo]').html(`<p class="proyect-title header-line header-line__thin__gray header-line__thin__gray--right">PROYECTO<br/><span class="highlight-txt">${pName}</span></p>
                <p class="no-margin-bottom"><a href="/proyecto/?${onSale[0].attributes.slug}"><img src="/assets/img/arrow-right-red.svg" width="12px"></a> Lince</p>
                <a href="/proyecto/?${onSale[0].attributes.slug}" class="button margin-y__top--small">VER +</a>`);


            }).done(function() {
                console.log( "donde: second success" );

            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });


            // INDEX Nuestro prop
            $.getJSON(apiProduction + "/api/indx-nuestro-proposito?populate=*", (data) => {
                // handle success
                let rawData = data;
                let nuestroProposito = rawData.data;
                // console.log({nuestroProposito});
                const { imagen_A, imagen_B, imagen_C } = nuestroProposito.attributes;
                $("[data-strapi-nproposito-mtitle]").html(nuestroProposito.titulo_seccion);
                $("[data-strapi-nproposito-title-a]").html(nuestroProposito.mdA_titulo);
                $("[data-strapi-nproposito-copy-a]").html(nuestroProposito.mdA_copy);

                $("[data-strapi-nproposito-btn]").html(`<a role="link" href="${nuestroProposito.attributes.mdA_boton_link}" class="hollow button" >${nuestroProposito.attributes.mdA_boton_copy}</a>`);
                $("[data-strapi-nproposito-imagen-a]").html(`<img src="${APIPath}${imagen_A.data.attributes.url}">`);
                $("[data-strapi-nproposito-subtitle-a]").html(nuestroProposito.attributes.mdA_sub_titulo);
                $("[data-strapi-nproposito-subcopy-a]").html(nuestroProposito.attributes.mdA_sub_copy);
                $("[data-strapi-nproposito-title-b]").html(nuestroProposito.attributes.mdB_titulo);
                $("[data-strapi-nproposito-copy-b]").html(nuestroProposito.attributes.mdB_copy);
                $("[data-strapi-nproposito-imagen-b]").html(`<img src="${APIPath}${imagen_B.data.attributes.url}">`);
                $("[data-strapi-nproposito-btn-b]").html(`<a role="link" href="${nuestroProposito.attributes.mdB_boton_link}" class="hollow button" >${nuestroProposito.attributes.mdB_boton_copy}</a>`);
                $("[data-strapi-nproposito-imagen-c]").html(`<img src="${APIPath}${imagen_C.data.attributes.url}">`);
                // style="max-width: 35vw;" 
                // style="margin-top: calc(100% - 20%);"

                

            }).done(function() {
                console.log( "donde: second success" );

            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });

            $.getJSON(apiProduction + "/api/indx-barrio?populate=*", (data) => {
                let rawData = data;
                let inBarrio = rawData.data;

                $("[data-strapi-ibarrio-mtitle]").html(inBarrio.attributes.titulo_seccion);
                $("[data-strapi-ibarrio-title]").html(inBarrio.attributes.md_titulo);
                $("[data-strapi-ibarrio-copy]").html(inBarrio.attributes.md_copy);

                // console.log("lala "+inBarrio.attributes.md_imagen.data.attributes.url);
                $("[data-strapi-ibarrio-btn]").html(`<a role="link" href="${inBarrio.attributes.md_boton_link}" class="hollow button" >${inBarrio.attributes.md_boton_copy}</a>`);
                $("[data-strapi-ibarrio-imagen]").html(`<img src="${APIPath}${inBarrio.attributes.md_imagen.data.attributes.url}">`);
                $("[data-strapi-ibarrio-subtitle]").html(inBarrio.attributes.md_sub_copy);

                let carouselBarrio = [];

                // TODO: Test mobile -> change bg image
                if(currMediaQuery === "small"){
                    carouselBarrio = inBarrio.attributes.md_carousel_movil.data;
                }else{
                    carouselBarrio = inBarrio.attributes.md_carousel.data;
                }
                
                $('[data-strapi-inbarrio-carrousel]').html(() => {
                    let carouselStrng = "";
                    carouselBarrio.forEach((carousel, index) => {
                        carouselStrng += `<div><img src="${APIPath}${carousel.attributes.url}"></div>`;
                    });
                    return carouselStrng;
                });
                
                // <div class="carrousel-index-barrio" data-strapi-inbarrio-carrousel></div>

            }).done(function() {
                console.log( "donde: second success" );

                $('.carrousel-index-barrio').slick({
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2500,
                    slidesToShow: 1,
                    // variableWidth: true,
                    // variableWidth: true,
                    fade: true,
                    arrows: false
                });
            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });
            
            // INDEX Proyectos entrgados
            $.getJSON(apiProduction + "/api/indx-proyectos?populate=*", (data) => {
                let rawData = data;
                let pryEntrgados = rawData.data;

                $("[data-strapi-pentrgados-mtitle]").html(pryEntrgados.attributes.titulo_seccion);
                $("[data-strapi-pentrgados-title]").html(pryEntrgados.attributes.md_titulo);
                $("[data-strapi-pentrgados-copy]").html(pryEntrgados.attributes.md_copy);

                // console.log("lala "+inBarrio.attributes.md_imagen.data.attributes.url);
                $("[data-strapi-pentrgados-btn]").html(`<a role="link" href="${pryEntrgados.attributes.md_boton_link}" class="hollow button" >${pryEntrgados.attributes.md_boton_copy}</a>`);
                $("[data-strapi-pentrgados-imagen]").html(`<img src="${APIPath}${pryEntrgados.attributes.md_imagen.data.attributes.url}">`);
                $("[data-strapi-pentrgados-subcopy]").html(pryEntrgados.attributes.md_sub_copy);

            }).done(function() {
                console.log( "donde: second success" );
            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });

            // INDEX Blog
            $.getJSON(apiProduction + "/api/indx-blog?populate[0]=articulos.miniatura&populate[1]=articulo_destacado&populate[2]=imagen_principal", (data) => {
                let rawData = data;
                // console.log(data);
                let dataIndxBlog = rawData.data;

                $("[data-strapi-blog-title]").html(dataIndxBlog.attributes.titulo_seccion);

                let highlight = dataIndxBlog.attributes.articulo_destacado;

                // console.log({dataIndxBlog});
                // console.log({highlight});

                $("[data-strapi-blog-top-title]").html(`<a role="link" href="/blog/?${highlight.data.attributes.slug}">${highlight.data.attributes.titulo}</a>`);

                let articleExtract = truncateString(highlight.data.attributes.articulo, 100);
                $("[data-strapi-blog-top-extract]").html(articleExtract);
                $("[data-strapi-blog-top-link]").html(`<a role="link" href="/blog/?${highlight.data.attributes.slug}" class="hollow button">LEER +</a>`);
                // console.log("lala "+inBarrio.attributes.md_imagen.data.attributes.url);
                // $("[data-strapi-pentrgados-btn]").html(`<a role="link" href="${dataIndxBlog.attributes.md_boton_link}" class="hollow button" >${pryEntrgados.attributes.md_boton_copy}</a>`);
                $("[data-strapi-blog-imagen]").html(`<img src="${APIPath}${dataIndxBlog.attributes.imagen_principal.data.attributes.url}">`);
                // $("[data-strapi-pentrgados-subcopy]").html(dataIndxBlog.attributes.md_sub_copy);

                let dataArticulos = dataIndxBlog.attributes.articulos;
                let articleExtractA = truncateString(dataArticulos.data[0].attributes.articulo,100);
                let articleExtractB = truncateString(dataArticulos.data[1].attributes.articulo,100);
                $("[data-strapi-blog-artleft]").html(`
                <div class="cell large-4 medium-5 small-5 float-relative-top">
                    <!-- <img class="arrow arrow--float arrow--float-right" src="/assets/img/arrow-right-white.svg"> -->
                    <figure class="blog-cover">
                    <a role="link" href="/blog/?${dataArticulos.data[0].attributes.slug}">
                        <img src="${APIPath}${dataArticulos.data[0].attributes.miniatura.data.attributes.url}">
                    </a>
                    </figure>
                </div>
                <div class="cell large-4 medium-4 small-7 padding-x--large">
                    <h4><a role="link" href="/blog/?${dataArticulos.data[0].attributes.slug}">${dataArticulos.data[0].attributes.titulo}</a></h4>
                    <p>${articleExtractA}</p>
                    <a role="link" href="/blog/?${dataArticulos.data[0].attributes.slug}"><img class="arrow" src="/assets/img/arrow-right-red.svg" ></a>
                </div>
                <div class="cell large-4 medium-auto "></div>`
                );

                $("[data-strapi-blog-artright-m]").html(`<div class="cell small-5 float-relative-top">
                    <!-- <img class="arrow arrow--float arrow--float-right" src="/assets/img/arrow-right-white.svg"> -->
                    <figure class="blog-cover">
                    <a role="link" href="/blog/?${dataArticulos.data[1].attributes.slug}">
                        <img src="${APIPath}${dataArticulos.data[1].attributes.miniatura.data.attributes.url}">
                    </a>
                    </figure>
                </div>
                <div class="cell small-7 padding-x--large">
                    <h4>${dataArticulos.data[1].attributes.titulo}</h4>
                    <p>${articleExtractB}</p>
                    <a role="link" href="/blog/?${dataArticulos.data[1].attributes.slug}"><img class="arrow" src="/assets/img/arrow-right-red.svg" ></a>
                </div>`);

                $("[data-strapi-blog-artright]").html(
                `<div class="cell large-4 medium-2"></div>
                <div class="cell large-4 medium-4 small-7 padding-x--large align-self-bottom">
                    <h4><a role="link" href="/blog/?${dataArticulos.data[1].attributes.slug}">${dataArticulos.data[1].attributes.titulo}</a></h4>
                    <p>${articleExtractB}</p>
                    <a role="link" href="/blog/?${dataArticulos.data[1].attributes.slug}"><img class="arrow" src="/assets/img/arrow-right-red.svg" ></a>
                </div>
                <div class="cell large-shrink medium-1"></div>
                <div class="cell large-4 medium-5 small-5 float-relative-top">
                    
                    <figure class="blog-cover">
                    <a role="link" href="/blog/?${dataArticulos.data[1].attributes.slug}">
                        <img src="${APIPath}${dataArticulos.data[1].attributes.miniatura.data.attributes.url}">
                    </a>
                    </figure>
                </div>`
                );

            }).done(function() {
                console.log( "donde: second success" );
            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });

            // INDEX Carousel Projects
            $.getJSON(apiProduction + "/api/indx-proyecto-carousel?populate=*", (data) => {
                let rawData = data;
                let dataCarousel = rawData.data;
                console.log({dataCarousel});
                let carouselProject = []; //dataCarousel.attributes.carousel_proyecto.data;

                if(currMediaQuery === "small"){
                    carouselProject = dataCarousel.attributes.carousel_proyecto_movil.data;
                }else{
                    carouselProject = dataCarousel.attributes.carousel_proyecto.data;
                }

                $('[data-strapi-inproject-carrousel]').html(() => {
                    let carouselStrng = "";
                    carouselProject.forEach((carousel, index) => {
                        carouselStrng += `<div><img src="${APIPath}${carousel.attributes.url}"></div>`;
                    });
                    return carouselStrng;
                });

            }).done(function() {
                console.log( "donde: second success" );

                $('.carrousel-index-proyecto').slick({
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2500,
                    slidesToShow: 1,
                    // variableWidth: true,
                    // variableWidth: true,
                    fade: true,
                    arrows: false
                });
            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });

            // INDEX Se Quadrat
            $.getJSON(apiProduction + "/api/indx-se-parte-de-quadrat?populate=*", (data) => {
                let rawData = data;
                let dataSeQuadrat = rawData.data;

                $("[data-strapi-sequadrat-mtitle]").html(dataSeQuadrat.attributes.titulo_seccion);
                $("[data-strapi-sequadrat-title]").html(dataSeQuadrat.attributes.titulo);
                $("[data-strapi-sequadrat-copy]").html(dataSeQuadrat.attributes.copy);
                $("[data-strapi-sequadrat-btncopy]").html(dataSeQuadrat.attributes.boton_copy);
                $("[data-strapi-sequadrat-imagen]").html(`<img src="${APIPath}${dataSeQuadrat.attributes.imagen_principal.data.attributes.url}">`);
                // console.log("lala "+inBarrio.attributes.md_imagen.data.attributes.url);

            }).done(function() {
                console.log( "donde: second success" );
            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });

            // INDEX Se Footer
        };


        const getNosotros = () => {
            $.getJSON(apiProduction + "/api/seccion-nosotros?populate=*", (data) => {
                console.log( "STRAPI DATA Nosotros" );    
                // console.log( {data} );
                let rawData = data;
                let attributes = rawData.data.attributes;

                $('[data-strapi-md1-title]').html(attributes.md_01_titulo);
                $('[data-strapi-md1-copy]').html(attributes.md_01_copy);
                $('[data-strapi-md1-image]').html(`<img class="adjust-w-image" src="${APIPath}${attributes.md_01_imagen.data.attributes.url}">`);

                $('[data-strapi-md2-title]').html(attributes.md_02_titulo);
                $('[data-strapi-md2-copy]').html(attributes.md_02_copy);
                $('[data-strapi-md2-image]').html(`<img src="${APIPath}${attributes.md_02_imagen.data.attributes.url}">`);

                // $('[data-strapi-md3-title]').html(attributes.md_03_titulo);
                $('[data-strapi-md3-copy]').html(attributes.md_03_copy);
                $('[data-strapi-md3-image]').html(`<img src="${APIPath}${attributes.md_03_imagen.data.attributes.url}">`);

                $('[data-strapi-md4-title]').html(attributes.md_04_titulo);
                $('[data-strapi-md4-copy]').html(attributes.md_04_copy);
                $('[data-strapi-md4-image]').html(`<img src="${APIPath}${attributes.md_04_imagen.data.attributes.url}">`);

            }).done(function() {
                console.log( "donde: second success" );
            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });
        }

        const setCopyCut = () => {
            
        }

        const getBarrios = () => {
            $.getJSON(apiProduction + "/api/proyectos?filters[enventa][$eq]=true&populate=*", (data) => {
                console.log( `STRAPI DATA Proyecto / ${PROJECT_NAME}` );    
                let rawData = data;
                let onSale = rawData.data;
                console.log({onSale});
                // let proyectos = rawData.data;  
                // // onSaleName = onSale[0].attributes.slug;

                // OnSale ver+
                $('[data-strapi-mp-title]').html(onSale[0].attributes.py_titulo);
                $('[data-strapi-mp-copy]').html(onSale[0].attributes.py_mensaje);
                // $('[data-strapi-mp-name]').html(toUpperCase(proyectos[0].attributes.nombre));
                $('[data-strapi-mp-alert]').html(onSale[0].attributes.py_alerta);
                $('[data-strapi-mp-image]').html(`<img src="${APIPath}${onSale[0].attributes.imagen_galeria.data.attributes.url}"></img>`);
                
                let pName = onSale[0].attributes.nombre
                pName = pName.toUpperCase();
                $('[data-strapi-mp-pinfo]').html(`<p class="proyect-title header-line header-line__thin__gray header-line__thin__gray--right">PROYECTO<br/><span class="highlight-txt">${pName}</span></p>
                <p class="no-margin-bottom"><a href="/proyecto/?${onSale[0].attributes.slug}"><img class="arrow--small" src="/assets/img/arrow-right-red.svg"></a>  ${onSale[0].attributes.distrito}</p>
                <a href="/proyecto/?${onSale[0].attributes.slug}" class="button margin-y__top--small">VER +</a>`);

            }).done(function() {
                console.log( "donde: second success" );

            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });


            $.getJSON(apiProduction + "/api/barrios?filters[slug][$eq]=lincidro&populate=*", (data) => {
                console.log( "STRAPI DATA BARRIOS" );    
                // console.log( {data} );
                let rawData = data;
                let dataBarrio = rawData.data[0].attributes;
                // console.log( dataBarrio.barrio_imagen_m4.data.attributes.url );



                $('[data-strapi-br1-title]').html(dataBarrio.barrio_titulo_m1);
                $('[data-strapi-br1-copy]').html(dataBarrio.barrio_copy_m1);
                $('[data-strapi-br1-image]').html(`<img class="show-for-medium" src="${APIPath}${dataBarrio.barrio_imagen_m1.data.attributes.url}">`);

                $('[data-strapi-br2-title]').html(dataBarrio.barrio_titulo_m2);
                $('[data-strapi-br2-copy]').html(dataBarrio.barrio_copy_m2);
                $('[data-strapi-br2-image]').html(`<img class="adjust-w-image" src="${APIPath}${dataBarrio.barrio_imagen_m2.data.attributes.url}">`);

                $('[data-strapi-br3-title]').html(dataBarrio.barrio_titulo_m3);
                $('[data-strapi-br3-copy]').html(dataBarrio.barrio_copy_m3);
                $('[data-strapi-br3-image]').html(`<img src="${APIPath}${dataBarrio.barrio_imagen_m3.data.attributes.url}">`);

                $('[data-strapi-br4-title]').html(dataBarrio.barrio_titulo_m4);
                $('[data-strapi-br4-copy]').html(dataBarrio.barrio_copy_m4);
                $('[data-strapi-br4-image]').html(`<img src="${APIPath}${dataBarrio.barrio_imagen_m4.data.attributes.url}">`);

                $('[data-strapi-br5-title]').html(dataBarrio.barrio_titulo_m5);
                $('[data-strapi-br5-copy]').html(dataBarrio.barrio_copy_m5);
                $('[data-strapi-br5-image]').html(`<img src="${APIPath}${dataBarrio.barrio_imagen_m5.data.attributes.url}">`);

                // console.log(dataBarrio.carousel_barrio.data);

                let carouselBarrio = []; //dataBarrio.carousel_barrio.data;

                if(currMediaQuery === "small"){
                    carouselBarrio = dataBarrio.carousel_barrio_movil.data;
                }else{
                    carouselBarrio = dataBarrio.carousel_barrio.data;
                }

                $('[data-strapi-barrio-carrousel]').html(() => {
                    let carouselStrng = "";
                    carouselBarrio.forEach((carousel, index) => {
                        carouselStrng += `<div><img src="${APIPath}${carousel.attributes.url}"></div>`;
                    });
                    return carouselStrng;
                });

                // filter services
                $.getJSON(apiProduction + "/api/barrios?filters[slug][$eq]=lincidro&populate=servicios&populate[1]=servicios.icono", (data) => {
                    console.log( "STRAPI DATA Servicio" );    
                    let rawData = data;
                    let servicios = rawData.data[0].attributes.servicios.data;  
                    console.log(rawData);
                    // [0].attributes.icono.data.attributes.url

                    $('[data-strapi-barrio-servicios]').html(() => {
                        let serviciosStrng = "";
                        servicios.forEach((servicio, index) => {
                            let iconPath = servicio.attributes.icono.data.attributes.url;
                            serviciosStrng += `<img class="ico-margin-right--medium ico-size" src="${APIPath}${iconPath}"></img>`;
                        });

                        return serviciosStrng
                    });

                }).done(function() {
                    console.log( "donde: second success" );
                }).fail(function() {
                    console.log( "error" ); 
                }).always(function() {
                    console.log( "always: complete" );
                });

            }).done(function() {
                console.log( "donde: second success" );

                $('.carrousel-barrio').slick({
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2500,
                    slidesToShow: 1,
                    // variableWidth: true,
                    // variableWidth: true,
                    fade: true,
                    arrows: false
                });

            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });


            // OFF SALE
            $.getJSON(apiProduction + "/api/proyectos?filters[enventa][$eq]=false&populate=*", (data) => {
                // console.log( `STRAPI DATA Proyecto / ${PROJECT_NAME}` );    
                let rawData = data;
                let OffSale = rawData.data;
                console.log({OffSale});

                let dataStrapiMpSLUG = OffSale[0].attributes.slug;
                let dataStrapiMpName = OffSale[0].attributes.nombre;
                let dataStrapiMpTitle = OffSale[0].attributes.py_titulo;
                let dataStrapiMpCopy = OffSale[0].attributes.py_mensaje;
                let dataStrapiMpImage = `<img src="${apiProduction+OffSale[0].attributes.imagen_galeria.data.attributes.url}"></img>`;

                OffSale.forEach((offProyect, index) => {
                    let pyName = offProyect.attributes.nombre;
                    pyName = pyName.toUpperCase();
                    $(`[data-strapi-${offProyect.attributes.slug}-name]`).html(pyName);
                    // $('[data-strapi-agora-district]').html(offProyect.attributes.py_titulo);
                    $(`[data-strapi-${offProyect.attributes.slug}-title]`).html(offProyect.attributes.py_titulo);
                    $(`[data-strapi-${offProyect.attributes.slug}-copy]`).html(offProyect.attributes.py_mensaje);
                    $(`[data-strapi-${offProyect.attributes.slug}-image]`).html(`<img src="${apiProduction+offProyect.attributes.imagen_galeria.data.attributes.url}"></img>`);
                    $(`[data-strapi-${offProyect.attributes.slug}-district]`).html(`<img class="arrow--small" src="assets/img/arrow-right-red.svg"> ${offProyect.attributes.distrito}`);

                    // carouselStrng += `<div><img src="${APIPath}${carousel.attributes.url}"></div>`;
                });

            }).done(function() {
                console.log( "donde: second success" );

            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });
        }
        

        const getProyectos = () => {
            $.getJSON(apiProduction + "/api/seccion-proyectos?populate=*", (data) => {
                console.log( "STRAPI DATA Proyectos" );    
                // console.log( {data} );
                let rawData = data;
                let scProyectos = rawData.data.attributes;

                $('[data-strapi-proyectos-title]').html(scProyectos.titulo_seccion);
                $('[data-strapi-proyectos-copy]').html(scProyectos.intro_proyectos);
                $('[data-strapi-proyectos-image]').html(`<figure class="header-cover">
                <img src="${APIPath}${scProyectos.imagen_principal_cabecera.data.attributes.url}">
                </figure>`);

            }).done(function() {
                console.log( "donde: second success" );
            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });

            // ON SALE
            $.getJSON(apiProduction + "/api/proyectos?filters[enventa][$eq]=true&populate=*", (data) => {
                console.log( `STRAPI DATA Proyecto / ${PROJECT_NAME}` );    
                let rawData = data;
                let onSale = rawData.data;
                console.log({onSale});
                // let proyectos = rawData.data;  
                // // onSaleName = onSale[0].attributes.slug;

                // OnSale ver+
                $('[data-strapi-mp-title]').html(onSale[0].attributes.py_titulo);
                $('[data-strapi-mp-copy]').html(onSale[0].attributes.py_mensaje);
                // $('[data-strapi-mp-name]').html(toUpperCase(proyectos[0].attributes.nombre));
                $('[data-strapi-mp-alert]').html(onSale[0].attributes.py_alerta);
                $('[data-strapi-mp-image]').html(`<img src="${apiProduction+onSale[0].attributes.imagen_galeria.data.attributes.url}"></img>`);
                
                let pName = onSale[0].attributes.nombre
                pName = pName.toUpperCase();
                $('[data-strapi-mp-pinfo]').html(`<p class="proyect-title header-line header-line__thin__gray header-line__thin__gray--right">PROYECTO<br/><span class="highlight-txt">${pName}</span></p>
                <p class="no-margin-bottom"><a href="/proyecto/?${onSale[0].attributes.slug}"><img class="arrow--small" src="/assets/img/arrow-right-red.svg"></a> ${onSale[0].attributes.distrito}</p>
                <a href="/proyecto/?${onSale[0].attributes.slug}" class="button margin-y__top--small">VER +</a>`);

            }).done(function() {
                console.log( "donde: second success" );

            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });

            // OFF SALE
            $.getJSON(apiProduction + "/api/proyectos?filters[enventa][$eq]=false&populate=*", (data) => {
                // console.log( `STRAPI DATA Proyecto / ${PROJECT_NAME}` );    
                let rawData = data;
                let OffSale = rawData.data;
                console.log({OffSale});

                let dataStrapiMpSLUG = OffSale[0].attributes.slug;
                let dataStrapiMpName = OffSale[0].attributes.nombre;
                let dataStrapiMpTitle = OffSale[0].attributes.py_titulo;
                let dataStrapiMpCopy = OffSale[0].attributes.py_mensaje;
                let dataStrapiMpImage = `<img src="${apiProduction+OffSale[0].attributes.imagen_galeria.data.attributes.url}"></img>`;

                OffSale.forEach((offProyect, index) => {
                    let pyName = offProyect.attributes.nombre;
                    pyName = pyName.toUpperCase();
                    $(`[data-strapi-${offProyect.attributes.slug}-name]`).html(pyName);
                    // $('[data-strapi-agora-district]').html(offProyect.attributes.py_titulo);
                    $(`[data-strapi-${offProyect.attributes.slug}-title]`).html(offProyect.attributes.py_titulo);
                    $(`[data-strapi-${offProyect.attributes.slug}-copy]`).html(offProyect.attributes.py_mensaje);
                    $(`[data-strapi-${offProyect.attributes.slug}-image]`).html(`<img src="${apiProduction+offProyect.attributes.imagen_galeria.data.attributes.url}"></img>`);
                    $(`[data-strapi-${offProyect.attributes.slug}-district]`).html(`<img class="arrow--small" src="assets/img/arrow-right-red.svg"> ${offProyect.attributes.distrito}`);

                    // carouselStrng += `<div><img src="${APIPath}${carousel.attributes.url}"></div>`;
                });
                
                

                

            }).done(function() {
                console.log( "donde: second success" );

            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });
        }

        

        /**
         * This function is used to get the data from the strapi API and then build the HTML for the
         * page
         */
        let PROJECT_NAME = '';
        const getProyecto = () => {            
            let queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            for (let p of urlParams) {
                PROJECT_NAME = p[0];
            };

            if(currMediaQuery === "small"){
                $('[pdf-download]').html(`<p>Cónoce nuestro proyecto,<br><a href="/static/Brochure_Proyecto_ISSOLA.pdf" target="_blank" download="Brochure_Proyecto_ISSOLA.pdf"><u>descarga el brochure.</u></a></p>`);
            }else{
                $('[pdf-download]').html(`<p>Cónoce nuestro proyecto,<br><a href="/static/Brochure_Proyecto_ISSOLA_dsktp.pdf" target="_blank" download="Brochure_Proyecto_ISSOLA.pdf"><u>descarga el brochure.</u></a></p>`);
            } 


            $.getJSON(apiProduction + "/api/proyectos?filters[slug][$eq]="+returnURLParams()+"&populate=*", (data) => {
                console.log( `STRAPI DATA Proyecto / ${PROJECT_NAME}` );    
                let rawData = data;
                let onSale = rawData.data;
                let proyectos = rawData.data;  

                // OnSale Page
                $('[data-strapi-proyectos-image]').html(`<figure class="header-cover header-cover--proyecto"><img src="${APIPath}${onSale[0].attributes.imagen_cover.data.attributes.url}"></figure>`);
                $('[data-strapi-pp-depas]').html(`${onSale[0].attributes.total_departamentos}<br><span>depas</span>`);
                $('[data-strapi-pp-pisos]').html(`${onSale[0].attributes.pisos}<br><span>pisos</span>`);
                $('[data-strapi-pp-lobby]').html(`<img class="lazyload" src="${APIPath}${onSale[0].attributes.imagen_lobby.data.attributes.url}">`);

                let carouselRender = []; //onSale[0].attributes.carousel_render.data;
                let carouselBarrio = []; //onSale[0].attributes.carousel_barrio.data;

                if(currMediaQuery === "small"){
                    carouselRender = onSale[0].attributes.carousel_render_movil.data;
                    carouselBarrio = onSale[0].attributes.carousel_barrio_movil.data;

                }else{
                    carouselRender = onSale[0].attributes.carousel_render.data;
                    carouselBarrio = onSale[0].attributes.carousel_barrio.data;

                }

                
                $('[data-strapi-proyecto-render]').html(() => {
                    let carouselStrng = "";
                    carouselRender.forEach((carousel, index) => {
                        carouselStrng += `<div><img src="${APIPath}${carousel.attributes.url}"></div>`;
                    });
                    return carouselStrng;
                });
                
                $('[data-strapi-proyecto-barrio]').html(() => {
                    let carouselStrng = "";
                    carouselBarrio.forEach((carousel, index) => {
                        carouselStrng += `<div><img src="${APIPath}${carousel.attributes.url}"></div>`;
                    });
                    return carouselStrng;
                });
            
                // STRAPI DATA Proyectos / ISSOLA / Apartment
                $.getJSON(apiProduction + "/api/departamentos?populate=*&filters[proyecto][slug][$eq]="+PROJECT_NAME, (data) => {
                    console.log( `STRAPI DATA Proyectos / ${PROJECT_NAME} / Apartment` );    
                    let rawData = data; 
                    projectApartmentData = rawData.data;

                    let arr = [];
                    let apartmentArr = [];

                    // console.log({projectApartmentData});

                    // buid arr of apartmens (rooms)
                    projectApartmentData.forEach((apartment, index) => {
                        let nombre = apartment.attributes.nombre;
                        let cuartos = apartment.attributes.cuartos;
                        let tipo = apartment.attributes.tipo;
                        let enventa = apartment.attributes.enventa;
                        let code = apartment.attributes.sperant_depa_code;

                        apartmentArr.push({name: nombre, rooms: cuartos, type:tipo, onsale: enventa, code: code});

                        if(tipo === 'Duplex'){
                            arr.push({group: tipo, id:10});
                        }else{
                            arr.push({group: `${cuartos} DORM.`, id: cuartos});
                        }
                    });

                    
                    $.post(phpPath+'get-apartment-id.php', PROJECT_NAME, function(data){
                        console.log('request > get-apartment-id.php?'+PROJECT_NAME);
                        let rawData = data;
                        let apartments = rawData;
                        let aLength = Object.keys(rawData).length;
                        console.log({apartments });

                        // let code = response.data[0].attributes.code;
                        apartments.forEach((apartment, index)=>{
                            // [0].attributes.code
                            apartment.forEach((item, index) => {                                
                                console.log(item.attributes.code, index);
                                sperant_depa_data.push({code:item.attributes.code, id:item.attributes.id})
                            });
                            // console.log(apartment[index].attributes.code);
                        });

                        // apartmentArr.forEach((item, index) => {
                        //     if(item.code === )
                        // });
                        // console.log({response});
                        // console.log(response.data[0].attributes.code, response.data[0].attributes.id);

                    }, "json");
                    
                    // let apartmentArr = arr;
                    let uniqueArr = getUnique(arr,'group');
                    uniqueArr.sort((a, b) => {return a.id - b.id;});         
                    let strng = "";
                    let aIDTst = "";
                    // aIDTst.push("jose");
                    // build boton -> 1 Dorm. 2 Dorms. etc

                    // listApartmentsByNum('#jsDpsdds', apartmentArr, item.id, "");
                    // listApartmentsByNum('#jsDps2dds', apartmentArr, item.id, "a");
                    // listApartmentsByNum('#jsDpsMdds', apartmentArr, item.id, "a1"); 

                    // for 1st form
                    $('[data-strapi-proyecto-depas-btn]').html(() => {
                        strng = "";
                        uniqueArr.forEach((item, index) => {      
                                      
                            if(item.group === "Duplex"){
                                strng += `<button id="DPLX" class="button hollow">DUPLEX</button>`;
                                $('[data-strapi-proyecto-depas-btn]').on('click', '#DPLX', function(){
                                    listApartmentsByNum('#jsDpsdds', apartmentArr, item.id, "");
                                    listApartmentsByNum('#jsDps2dds', apartmentArr, item.id, "a");
                                    listApartmentsByNum('#jsDpsMdds', apartmentArr, item.id, "a1");           
                                });
                            }else{
                                let idObj = `0${index+1}D`;
                                strng += `<button id="${idObj}" class="button hollow">${item.group}</button>`;
                                $('[data-strapi-proyecto-depas-btn]').on('click', `#${idObj}`, function(){
                                    listApartmentsByNum('#jsDpsdds', apartmentArr, item.id, "");
                                    listApartmentsByNum('#jsDps2dds', apartmentArr, item.id, "a");
                                    listApartmentsByNum('#jsDpsMdds', apartmentArr, item.id, "a1");                    
                                });
                            }                   
                        });
                        return strng;
                    });

                    //  for 2nd form
                    $('[data-strapi-proyecto-depas-btn2]').html(() => {
                        strng = "";
                        uniqueArr.forEach((item, index) => {                    
                            if(item.group === "Duplex"){
                                strng += `<button id="DPLX2d" class="button hollow">DUPLEX</button>`;
                                $('[data-strapi-proyecto-depas-btn2]').on('click', '#DPLX2d', function(){
                                    listApartmentsByNum('#jsDpsdds', apartmentArr, item.id, "");
                                    listApartmentsByNum('#jsDps2dds', apartmentArr, item.id, "a");
                                    listApartmentsByNum('#jsDpsMdds', apartmentArr, item.id, "a1");           
                                });
                            }else{
                                let idObj = `0${index+1}D2d`;
                                strng += `<button id="${idObj}" class="button hollow">${item.group}</button>`;
                                $('[data-strapi-proyecto-depas-btn2]').on('click', `#${idObj}`, function(){
                                    listApartmentsByNum('#jsDpsdds', apartmentArr, item.id, "");
                                    listApartmentsByNum('#jsDps2dds', apartmentArr, item.id, "a");
                                    listApartmentsByNum('#jsDpsMdds', apartmentArr, item.id, "a1");           
                                });
                            }                   
                        });
                        return strng;
                    });

                    //  for mobile form
                    $('[data-strapi-proyecto-depas-btnM]').html(() => {
                        strng = "";
                        uniqueArr.forEach((item, index) => {                    
                            if(item.group === "Duplex"){
                                strng += `<button id="DPLX2" class="button hollow">DUPLEX</button>`;
                                $('[data-strapi-proyecto-depas-btnM]').on('click', '#DPLX2', function(){
                                    listApartmentsByNum('#jsDpsdds', apartmentArr, item.id, "");
                                    listApartmentsByNum('#jsDps2dds', apartmentArr, item.id, "a");
                                    listApartmentsByNum('#jsDpsMdds', apartmentArr, item.id, "a1");           
                                });
                            }else{
                                let idObj = `0${index+1}D2`;
                                strng += `<button id="0${index+1}D2" class="button hollow">${item.group}</button>`;
                                $('[data-strapi-proyecto-depas-btnM]').on('click', `#${idObj}`, function(){
                                    listApartmentsByNum('#jsDpsdds', apartmentArr, item.id, "");
                                    listApartmentsByNum('#jsDps2dds', apartmentArr, item.id, "a");
                                    listApartmentsByNum('#jsDpsMdds', apartmentArr, item.id, "a1");           
                                });
                            }                   
                        });
                        return strng;
                    });

                }).done(function() {
                    console.log( "donde: second success" );
                }).fail(function() {
                    console.log( "error" ); 
                }).always(function() {
                    console.log( "always: complete" );
                });

            }).done(function() {
                console.log( "donde: second success" );
                $('.carrousel-proyecto-render').slick({
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2500,
                    slidesToShow: 1,
                    // variableWidth: true,
                    // variableWidth: true,
                    fade: true,
                    centerMode: true
                });

                $('.carrousel-proyecto-barrio').slick({
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2500,
                    slidesToShow: 1,
                    // variableWidth: true,
                    // variableWidth: true,
                    fade: true,
                    centerMode: true
                });

            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });  


            // STRAPI DATA Proyectos / ISSOLA / Market
            // $.getJSON(apiProduction + "/api/proyectos?filters[enventa][$eq]=true&populate=market&populate[1]=market.mapa_local_proyecto&populate[2]=market.mapa_local_proyecto.mapa", (data) => {
            $.getJSON(apiProduction + "/api/markets?populate=*", (data) => {
                console.log( `STRAPI DATA Proyectos / ${PROJECT_NAME} / Market` );    
                let rawData = data; 
                let nearMarket = rawData.data;              

                $.getJSON(apiProduction + "/api/markets?populate=mapa_local_proyecto.mapa", (data) => {
                    let rawData = data; 
                    let marketMap = rawData.data;
                    // console.log({marketMap});
                    buildMarket(nearMarket, marketMap);
                }).done(function() {
                    console.log( "donde: second success" );
                }).fail(function() {
                    console.log( "error" ); 
                }).always(function() {
                    console.log( "always: complete" );
                });

            }).done(function() {
                console.log( "donde: second success" );
            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });

            
        }


        const getBlog = () => {
            $.getJSON(apiProduction + "/api/blogs?sort[0]=id:desc&populate=*", (data) => {    
                let rawData = data; 
                let blogArticles = rawData.data; 
                let artList = "";
                let artListM = "";
                
                console.log({blogArticles});

                $("[data-strapi-blog-articles]").html(() => {
                    let sideA = true;
                    blogArticles.forEach((article, index) => {
                        console.log({article});
                        let aID = article.id;
                        let aTitle = article.attributes.titulo;
                        let aCopy = article.attributes.articulo;
                        let aSlug = article.attributes.slug;
                        let aCover = article.attributes.miniatura.data.attributes.url;
                        let aPubDate = article.attributes.publicacion;
                        
                        const d = new Date(aPubDate);
                        aPubDate = d.getUTCDate()+" "+monthNames[d.getMonth()]+" "+d.getFullYear();

                        aTitle = aTitle.substr(0, 56) + '...';

                        if(index % 2 === 0) { 
                            if(sideA) {
                                artList += leftOddB(aTitle,aCopy,aSlug,aCover,aPubDate);
                                sideA = false;
                            }else{                                
                                artList += rightEvenB(aTitle,aCopy,aSlug,aCover,aPubDate);  
                                sideA = true;
                            } 
                        }else{
                            if(sideA){
                                artList += rightEvenA(aTitle,aCopy,aSlug,aCover,aPubDate);                                
                                // sideA = false;
                            }else{
                                artList += leftOddA(aTitle,aCopy,aSlug,aCover,aPubDate);
                                // sideA = true;
                            } 
                        }
                    });

                    return artList;
                });

                $("[data-strapi-blog-articles-m]").html(() => {
                    // let sideA = true;
                    blogArticles.forEach((article, index) => {
                        console.log({article});
                        let aID = article.id;
                        let aTitle = article.attributes.titulo;
                        let aCopy = article.attributes.articulo;
                        let aSlug = article.attributes.slug;
                        let aCover = article.attributes.miniatura.data.attributes.url;
                        let aPubDate = article.attributes.publicacion;

                        const d = new Date(aPubDate);
                        aPubDate = d.getUTCDate()+" "+monthNames[d.getMonth()]+" "+d.getFullYear();

                        aTitle = aTitle.substr(0, 50) + '...';

                        if(aID % 2 == 0) {
                            artListM += rightEvenAm(aTitle,aCopy,aSlug,aCover,aPubDate); 
                        }else{
                            artListM += leftOddAm(aTitle,aCopy,aSlug,aCover,aPubDate);
                        }
                    });

                    return artListM;
                });
                
                

            }).done(function() {
                console.log( "donde: second success" );
            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });
        }
        
        /**
         * It gets the data from the strapi API and displays it on the page.
         */
        const getBlogPost = () => {
            let slugPost = "";
            let queryString = window.location.search;
            let url = window.location.href;
            let lastPart = url.split("/").pop();

            // console.log(lastPart);
            const urlParams = new URLSearchParams(queryString);
            for (let p of urlParams) {
                slugPost = p[0];
            }

            var clean_uri = location.protocol + "//" + location.host + location.pathname + slugPost;
            // window.history.replaceState({}, document.title, clean_uri);
            if(slugPost.length > 1){
                $.getJSON(apiProduction + "/api/blogs?filters[slug][$eq]="+returnURLParams()+"&populate=*", (data) => {
                    console.log( "STRAPI DATA Blogs" );    
                    // console.log( {data} );
                    let rawData = data;
                    let dataArticle = rawData.data[0];
                    console.log( dataArticle.attributes.titulo );
                    // dataArticle[0].attributes.articulo
                    // $('meta[name="twitter:title"]').attr("content", dataArticle.attributes.titulo);
                    
                    $('[data-strapi-article-title]').html(dataArticle.attributes.titulo);
                    $('[data-strapi-article-cont]').html(marked.parse(dataArticle.attributes.articulo));
                    
                    // $('[data-strapi-md1-image]').html(`<img class="adjust-w-image" src="${APIPath}${attributes.md_01_imagen.data.attributes.url}">`);

                }).done(function(data) {
                    console.log( "donde: second success" );
                    

                }).fail(function() {
                    console.log( "error" ); 
                }).always(function() {
                    console.log( "always: complete" );
                });
            }else{
                // go to 404
            }
        }
        
        /**
         * It gets the footer from the API and puts it in the HTML.
         */
        const getFooter = () => {
            $.getJSON(apiProduction + "/api/footer?populate=*", (data) => {
                let rawData = data;
                let dataFooter = rawData.data;
                console.log({dataFooter});
                $("[data-strapi-footer-contactinfo]").html(dataFooter.attributes.Informacion_contacto);
                $("[data-strapi-footer-googlemaps]").html(dataFooter.attributes.google_maps);
                
            }).done(function() {
                console.log( "donde: second success" );
            }).fail(function() {
                console.log( "error" ); 
            }).always(function() {
                console.log( "always: complete" );
            });
        }



        
        
        /**
         * It takes in 5 arguments, and returns a string of HTML
         * @param args - [0] = title, [1] = excerpt, [2] = slug, [3] = image, [4] = date
         * @returns A string.
         */
        const leftOddAm = (...args) => {
            return `<div class="grid-x large-6 small-12 margin-bottom--large">
                <div class="cell large-3 small-5 align-self-bottom text-right padding-x--plus">
                    <h5 class="header-line header-line__thin header-line__thin--right article-post-title">${args[0]}</h5>
                    <p class="blog--post-date">${args[4]}</p>
                    <p class="margin-no blog--read-more"><a href="/blog/?${args[2]}"><img class="arrow--small" src="assets/img/arrow-right-red.svg"> Leer más</a></p>
                </div>
                
                <div class="cell large-3 small-7 text-right">
                    <figure class="blog-cover">
                        <a href="/blog/?${args[2]}">
                        <img src="${APIPath}${args[3]}">
                        </a>
                    </figure>
                </div>
            </div>`;
        };

        
        /**
         * It takes in 5 arguments, and returns a string of HTML
         * @param args - [0] = title, [1] = excerpt, [2] = slug, [3] = image, [4] = date
         * @returns A string of HTML code.
         */
        const rightEvenAm = (...args) => {
            return `<div class="grid-x large-6 small-12 margin-bottom--large">
                <div class="cell large-3 small-7">
                    <figure class="blog-cover">
                        <a href="/blog/?${args[2]}">
                        <img src="${APIPath}${args[3]}">
                        </a>
                    </figure>
                </div>
                
                <div class="cell large-3 small-5 align-self-bottom padding-x--plus">
                    <h5 class="header-line header-line__thin article-post-title">${args[0]}</h5>
                    <p class="blog--post-date">${args[4]}</p>
                    <p class="margin-no blog--read-more"><a href="/blog/?${args[2]}"><img class="arrow--small" src="assets/img/arrow-right-red.svg"> Leer más</a></p>
                </div>
            </div>`;
        };
        
        /**
         * It takes in 5 arguments, and returns a string of HTML
         * @param args - [0] = title, [1] = excerpt, [2] = slug, [3] = image, [4] = date
         * @returns A string.
         */
        const leftOddA = (...args) => {
            return `<!-- leftOddA -->
            <article class="grid-x large-6 medium-6 padding-y--large">
                    <div class="cell large-6 medium-6">
                        <figure class="blog-cover">
                        <a href="/blog/?${args[2]}">
                        <img src="${APIPath}${args[3]}">
                        </a>
                        </figure>
                    </div>            
                    <div class="cell large-6 medium-6 align-self-bottom padding-x--medium">
                        <h4 class="header-line header-line__thin article-post-title">${args[0]}</h4>
                        <p class="blog--post-date">${args[4]}</p>
                        <p class="margin-no blog--read-more"><a href="/blog/?${args[2]}"><img src="/assets/img/arrow-right-red.svg" width="12px"> Leer más</a></p>

                    </div>
                </article>`;
        };

        
        /**
         * It takes in 5 arguments, and returns a string of HTML
         * @param args - [0] = title, [1] = excerpt, [2] = slug, [3] = image, [4] = date
         * @returns A string of HTML code.
         */
        const rightEvenA = (...args) => {
            return `<!-- rightEvenA -->
            <article class="grid-x large-6 medium-6 cell-bgcolor cell-bgcolor--pb cell-bgcolor--pb-blog-even padding-y--large">  
                    <div class="cell large-6 medium-6">
                        <figure class="blog-cover">
                        <a href="/blog/?${args[2]}">
                            <img  src="${APIPath}${args[3]}">
                        </a>
                        </figure>
                    </div>
                    <div class="cell large-6 medium-6 align-self-bottom padding-x--medium">
                        <h4 class="header-line header-line__thin article-post-title">${args[0]}</h4>
                        <p class="blog--post-date">${args[4]}</p>
                        <p class="margin-no blog--read-more"><a href="/blog/?${args[2]}"><img src="/assets/img/arrow-right-red.svg" width="12px"> Leer más</a></p>

                    </div>  
                </article>`;
        };

        
        
        /**
         * `leftOddB` is a function that takes in 5 arguments and returns a string of HTML
         * @param args - [0] = title, [1] = excerpt, [2] = slug, [3] = image, [4] = date
         * @returns A string of HTML code.
         */
        const leftOddB = (...args) => {
            // console.log({args});
            return `<!-- leftOddB -->
            <article class="grid-x large-6 medium-6 cell-bgcolor cell-bgcolor--pb cell-bgcolor--pb-blog-odd padding-y--large">  
                    <div class="cell large-6 medium-6">
                        <figure class="blog-cover">
                        <a href="/blog/?${args[2]}">
                            <img  src="${APIPath}${args[3]}">
                        </a>
                        </figure>
                    </div>
                    <div class="cell large-6 medium-6 align-self-bottom padding-x--medium">
                        <h4 class="header-line header-line__thin header-line__thin article-post-title">${args[0]}</h4>
                        <p class="blog--post-date">${args[4]}</p>
                        <p class="margin-no blog--read-more"><a href="/blog/?${args[2]}"><img src="/assets/img/arrow-right-red.svg" width="12px"> Leer más</a></p>
                    </div>
                </article>`;
        };

        
        /**
         * It takes in 5 arguments, and returns a string of HTML
         * @param args - [0] = title, [1] = excerpt, [2] = slug, [3] = image, [4] = date
         * @returns A string of HTML code.
         */
        const rightEvenB = (...args) => {
            return `<!-- rightEvenB -->
            <article class="grid-x large-6 medium-6 padding-y--large">
                    <div class="cell large-6 medium-6">
                        <figure class="blog-cover">
                        <a href="/blog/?${args[2]}">
                            <img  src="${APIPath}${args[3]}">
                        </a>
                        </figure>
                    </div>
                    <div class="cell large-6 medium-6 align-self-bottom padding-x--medium">
                        <h4 class="header-line header-line__thin article-post-title">${args[0]}</h4>
                        <p class="blog--post-date">${args[4]}</p>
                        <p class="margin-no blog--read-more"><a href="/blog/?${args[2]}"><img src="/assets/img/arrow-right-red.svg" width="12px"> Leer más</a></p>
                    </div>
                </article>`;
        };


        /**
         * It builds the market buttons.
         * @param nearMarket - an array of market objects
         * @param marketMap - The marketMap object that contains the market data.
         */
        const buildMarket = (nearMarket, marketMap) => {
            let idBtn = "";
            let idBtnM = "";
            let idItem ="";
            let idItemM ="";
            let marketBtns = "";
            let marketBtnsM = "";
            console.log({marketMap});
            console.log({nearMarket});
            
            
            $("[data-strapi-pry-market]").html(() => {
                console.log("hola ");

                nearMarket.forEach((item, index, array) => {
                    idItem = `d-${item.attributes.slug}-${index}`;
                    idBtn = `#${idItem}`;
                    marketBtns += `<div class="market-item"><a><img id="${idItem}" class="fgrayscale" src="${APIPath}${item.attributes.logo.data.attributes.url}"></a></div>`;
                
                    $("#js-near-market").on("click", idBtn, function(){
                        // console.log("OkBBNNTT");
                        showMarketInfoDesktop(
                            marketMap[index].attributes.mapa_local_proyecto[0].mapa.data.attributes.url,
                            item.attributes.logo.data.attributes.url,
                            item.attributes.mapa_local_proyecto[0].distancia_caminando_minutos,
                            item.attributes.mapa_local_proyecto[0].distancia_manejando_minutos
                            );
                    });
                });

                return marketBtns;
            });

            $("[data-strapi-pry-market-m]").html(() => {
                console.log("hola ");

                nearMarket.forEach((item, index, array) => {
                    idItemM = `m-${item.attributes.slug}-${index}`;
                    idBtnM = `#${idItemM}`;
                    marketBtnsM += `<div class="market-item"><a><img id="${idItemM}" class="fgrayscale" src="${APIPath}${item.attributes.logo.data.attributes.url}"></a></div>`;
                
                    $("#js-near-market-m").on("click", idBtnM, function(){
                        console.log("OkBBNNTT");
                        showMarketInfoDesktop(
                            marketMap[index].attributes.mapa_local_proyecto[0].mapa.data.attributes.url,
                            item.attributes.logo.data.attributes.url,
                            item.attributes.mapa_local_proyecto[0].distancia_caminando_minutos,
                            item.attributes.mapa_local_proyecto[0].distancia_manejando_minutos
                            );
                    });
                });

                return marketBtnsM;
            }); 

            
        };


        /**
         * This function is used to create the dropdown menu for each department
         * @param id - the id of the element that will be modified
         * @param arr - an array of objects that contain the information of the departments
         * @param room - the room number
         * @param mod - The modal that will be opened when the user clicks on the button.
         */
        const listApartmentsByNum = (parentID, arr, room, mod) => {
            
            $(parentID).html(() => {
                let str1dorm = "";
                let sDepasId = ""

                arr.sort(function(a, b){return a.name - b.name});
                console.log(arr);

                arr.forEach((item, index) => {
                    // console.log(item.type, item.rooms, room);

                    
                    let depaID = "";

                    if(item.type === "Duplex" && room === 10){
                        depaID = `DPX${item.name}${mod}`;  
                        sDepasId += `#${depaID},`;
                        if(!item.onsale){
                            str1dorm += `<li><a class="sell">${item.name}</a></li>`;
                        }else{
                            str1dorm += `<li><a id="${depaID}">${item.name}</a></li>`;
                        }

                        $(parentID).on("click", `#${depaID}`, function(event){                 
                            const newStrDepasId = sDepasId.slice(0, -1);
                            $(newStrDepasId).removeClass('active');
                            $(this).toggleClass('active');
                            // console.log($(this));
                            getFullDptoInfo($(this).text());
    
                            // console.log({newStrDepasId});  
                            // event.preventDefault();
                        });

                    }else if(item.rooms === room && room !== 10 && item.type !== "Duplex"){ //remove las one to make duplex abailable by number of rooms 
                        depaID = `0${item.rooms}d${item.name}${mod}`; 
                        sDepasId += `#${depaID},`;
                        if(!item.onsale){
                            str1dorm += `<li><a class="sell">${item.name}</a></li>`;
                        }else{
                            str1dorm += `<li><a id="${depaID}">${item.name}</a></li>`;
                        }
                        

                        $(parentID).on("click", `#${depaID}`, function(event){                 
                            const newStrDepasId = sDepasId.slice(0, -1);
                            $(newStrDepasId).removeClass('active');
                            $(this).toggleClass('active');
                            // console.log($(this));
                            getFullDptoInfo($(this).text());
    
                            // console.log({newStrDepasId});  
                            // event.preventDefault();
                        });
                    }
 
                    

                    // depaID = "";
                });               

                return `<ul class="n-dptos">${str1dorm}</ul>`;
            }); 
        }
    
        // let userHasScrolled = false;
        let isComsToolsActive = false;
        $('#jsComsToolsBttn').on("click", function(){
            if(isComsToolsActive){
                closeComs();
             } else {    
                $('#jsComsContainer').removeClass("coms-tools--hide").addClass("coms-tools--show");
                $('#jsComsTools').show(); //fadeIn(250); //.show(1);
                $('#jsComsToolsBttn').hide(); //.;
                isComsToolsActive = true;
            }
        });
        
        window.onscroll = (e) => {
            if(isComsToolsActive){
                setTimeout(closeComs, 1000);
            }
        }

        const closeComs = () => {
            $('#jsComsContainer').removeClass("coms-tools--show").addClass("coms-tools--hide");
            $('#jsComsTools').hide();
            $('#jsComsToolsBttn').show();
            isComsToolsActive = false;
        }
    
        /**
         * It sets the video to the video with the given id.
         * @param vid - The video ID.
         */
        const setVideo = (vid) => {
            var video = $('#jsVideo')[0];
            if(video){
                var mp4Vid = $('#mp4');
                mp4Vid.attr('src', apiProduction+vid);
                video.load();
                video.play();
                
                var videoloop = video;
                videoloop.addEventListener('ended', function() {
                    this.play();    
                }, false);
            }    
        }
        
        // eliminate repeat index 

        /**
         * Given an array, return the array with duplicate values removed
         * @param arr - the array to be filtered
         * @param comp - The property we want to compare.
         * @returns The unique values of the array.
         */
        const getUnique = (arr, comp) => {
            const unique =  arr.map(e => e[comp])
                // store the indexes of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)
                // eliminate the false indexes & return unique objects
                .filter((e) => arr[e]).map(e => arr[e]).sort();
            return unique;
        }

        
        
        // resetAll
        const resetMarketBtns = () => {
            $('#mBBVA,#mAntica,#mScarleti,#mBCP,#mBodega,#mInkafarma,#mSTRBKS,#mSnCerefino,#mMiFarma,#mPardos,#mBACOVaca,#mBlueMoon,#mBlueMoon,#mChilis').removeClass('active');
        }
    
        /**
         * It takes 4 parameters, and then it resets the market buttons, and then it sets the html of
         * the map, logo, minw, and mind
         * @param mapa - the name of the map image file (without the extension)
         * @param logo - the name of the logo image file (without the extension)
         * @param minw - minimum walking time
         * @param mind - minutes to drive
         */
        const showMarketInfo = (mapa,logo,minw,mind) => {
            resetMarketBtns();
            $('[data-nearmarket-map]').html(`<img src="${path}assets/img/mapas/${mapa}-m.png">`);
            $('[data-nearmarket-logo]').html(`<img src="${path}assets/img/ui/${logo}.png">`);
            $('[data-nearmarket-minw]').html(`${minw}<br><span>min</span>`);
            $('[data-nearmarket-mind]').html(`${mind}<br><span>min</span>`);
            // $("html, body").animate({ scrollTop: $("#jsnearMarketDataMap").offset().top - 80 }, 500); 
        }
    
        /**
         * It takes 4 parameters, and then it resets the market buttons, and then it puts the mapa and
         * logo into the mapdk and map divs, and then it puts the minw and mind into the minw and mind
         * divs
         * @param mapa - the map image
         * @param logo - the logo of the market
         * @param minw - minimum walking time
         * @param mind - minutes to drive
         */
        const showMarketInfoDesktop = (mapa,logo,minw,mind) => {
            resetMarketBtns();
            $('[data-nearmarket-mapdk], [data-nearmarket-map]').html(`<img class="near-market-mapa" src="${APIPath}${mapa}">`);
            // $('[data-nearmarket-map]').html(`<img class="near-market-mapa" src="${APIPath}${mapa}">`);
            $('[data-nearmarket-logo]').html(`<img src="${APIPath}${logo}">`);
            $('[data-nearmarket-minw]').html(`${minw}<br><span>min</span>`);
            $('[data-nearmarket-mind]').html(`${mind}<br><span>min</span>`);
        }

        // TODO: Elminate this Arrs
        // let sMainDpBtn = '#01D,#01D2,#01D2d,#02D,#02D2,#02D2d,#03D,#03D2,#03D2d,#DPLX,#DPLX2,#DPLX2d';
        let sDepas = "" // "#01dSS02,#01dSS03,#02dSS01,#02d101,#02d203,#02d401,#02d403,#02d501,#03d202,#03d302,#03d402,#DPX602,#01dSS02a,#01dSS03a,#02dSS01a,#02d101a,#02d203a,#02d401a,#02d403a,#02d501a,#03d202a,#03d302a,#03d402a,#DPX602a,#01dSS02a1,#01dSS03a1,#02dSS01a1,#02d101a1,#02d203a1,#02d401a1,#02d403a1,#02d501a1,#03d202a1,#03d302a1,#03d402a1,#DPX602a1";
    
        /**
         * This function is used to get the information of a department from the API
         * @param num - The number of the department.
         */
        const getFullDptoInfo = (num) => {
            // let carouselDepa = [];
            projectApartmentData.forEach(function (item, index, array) {

                if(item.attributes.nombre === num){
                    let dptAttributes = item.attributes;
                    console.log({dptAttributes});
                    
                    // #jsModalDepa
                    $('[data-depainfo-title]').html(`${dptAttributes.tipo} ${dptAttributes.nombre}`);
                    $('[data-depainfo-a]').html(`<p>${dptAttributes.piso_texto}</p><p>CUARTOS: 0${dptAttributes.cuartos}</p>`);
                    $('[data-depainfo-b]').html(`<p>ÁREA TOTAL: ${dptAttributes.area_total} m<sup>2</sup></p><p>BAÑOS: 0${dptAttributes.banos}</p>`);
                    $('[data-depainfo-cuartos]').html(`0${dptAttributes.cuartos}`);
                    $('[data-depainfo-banos]').html(`0${dptAttributes.banos}`);
                    $('[data-depainfo-autos]').html(`0${dptAttributes.autos}`);

                    // 
                    $('#jsModalDepa #ProjectId').val(dptAttributes.proyecto.data.attributes.slug);
                    $('#jsModalDepa #ApartmentId').val(dptAttributes.sperant_depa_id);
                    // 

                    sperant_depa_data.forEach((depa, index) => {

                        if(dptAttributes.sperant_depa_code === depa.code){
                            let idDepa = depa.id;
                            $('#jsModalDepa #ProjectId').val(PROJECT_NAME);
                            $('#jsModalDepa #ApartmentId').val(idDepa);  
                        }
                    })

                    $('#jsModalDepa #ProjectId').val();
                    $('#jsModalDepa #ApartmentId').val();              
    
                    // #jsDepaInfo2D
                    $('[data-depainfo-title2d]').html(`${dptAttributes.nombre} <span class="header-line header-line__thin header-line__thin--bottom reset-rotation">${dptAttributes.tipo}</span>`);
                    $('[data-depainfo-plano2d]').html(`<img src="${APIPath}${dptAttributes.plano.data.attributes.url}" >`);
                    $('[data-depainfo-extra]').html(`<p>${dptAttributes.area_total} m<sup>2</sup> <span style="color: #D92D27;">|</span> ${dptAttributes.cuartos} Dorm</p>`);
                    
                    // sperant_depa_data

                    let carouselStrng = "";
                    let carouselDepa = dptAttributes.render.data;
                    carouselDepa.forEach((carousel, index) => {
                        // console.log(carousel.attributes.url);
                        // style="height: 237px;" 
                        carouselStrng += `<div><img class="lazyload" src="${APIPath}${carousel.attributes.url}" ></div>`;                     
                    });
                    currCarousel(carouselStrng, carouselDepa.length);
                };  
            });     
        };  
        
        /**
         * This function takes in an array of items and a number, and returns the items in a carousel
         * @param items - The HTML to be inserted into the carousel.
         * @param n - The number of items to display.
         */
        const currCarousel = (items, n) => {
            if ($('.carrousel-proyecto-depa').hasClass('slick-initialized')) {
                $('.carrousel-proyecto-depa').slick('destroy');
            }

            $('[data-strapi-depa-carrousel]').html(items);
            
            if(n > 1){
                $('.carrousel-proyecto-depa').slick({
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2500,
                    slidesToShow: 1,
                    // variableWidth: true,
                    // variableWidth: true,
                    fade: true,
                    arrows: false
                });
            } 
        }


        /* This is tracking the lead event on Facebook. */
        $("#cotizarModal").on("open.zf.reveal", (ev) => {
            fbq('track', 'Lead');
            $("#jsComsContainer").hide();
        });

        /* This is tracking the lead event on Facebook. */
        $("#cotizarModal").on("closed.zf.reveal", (ev) => {
            // fbq('track', 'Lead');
            $("#jsComsContainer").show();
        });

        // FormCotizar
        // TODO: replace w real data.
        /* Creating a variable called pryName and setting it to the string "ETERNIA" */
        // let pryName = PROJECT_NAME;

        /* Creating a variable called idUnit and setting it equal to 7578. */
        // let idUnit = 7578;

        let formData = {};
        $("#jsFormCotizar,#jsFormCotizar1,#jsFormCotizar2").on("formvalid.zf.abide", (ev,form) => { 
            // form validation passed, form will submit if submit event not returned false
            // console.log("Form id "+form.attr('id')+" is valid");
            // ajax post form
            let formID = "#"+form.attr('id');
            let name = $(formID+" #Name").val();
            let number = $(formID+" #Number").val();
            let email = $(formID+" #Email").val();
            let project = $(formID+" #ProjectId").val();
            let apartment = $(formID+" #ApartmentId").val();
            // formData = $( formID ).serializeArray();

            formData = {
                "fname":name,
                "email":email,
                "phone":number,
                "project":project,
                "apartment":apartment
            }
            
            // console.log(formData.fname);

            $(".submit-message").html("");  

            // $.post(path+'mailing.php', $( formID ).serialize(), function(data){
            $.post(phpPath+'crmscript.php', $( formID ).serialize(), function(data){
                $("form").hide("fast");
                // console.log({data});
            }).done(function(data) {
                let phpResponse = data.exception;
                // console.table(phpResponse);
    
                if(data.exception){
                    $(".submit-message").show("fast")
                    $(".submit-message").html('<div style="font-size: 1.5rem; line-height: 2rem; white-space: break-spaces;" class="help-block label error">El mensaje no se pudo enviar, intente nuevamente o llamenos al +51989023009</div>');
                    setTimeout(function(){
                        $(".submit-message").hide("fast");
                        $("form").show("fast");
                    }, 4000);
                }else{
                    fbq('track', 'CompleteRegistration');

                    $("form").show("fast")
                    $(".submit-message").show("fast");
                    $(".submit-message").html('<div style="font-size: 1.5rem; line-height: 2rem; white-space: break-spaces;" class="help-block label success">Su cotización fue enviada exitosamente a <b>'+email+'</b>. Muchas gracias por contectarnos. <br><small>Está ventana se cerrara en unos segundos. Gracias.</small></div>');
                    setTimeout(() => {
                        $(".submit-message").hide("fast");
                        setTimeout(() => {
                            $('#cotizarModal').foundation('close');
                        }, 2000);
                    }, 4000);
                }
                
            }).fail(function() {
                $("form").show();
                $("form").html('<div style="font-size: 1.5rem; line-height: 2rem; white-space: break-spaces;" class="help-block label error">La cotización no se pudo enviar. Intente nuevamente o llamenos al <b>+51989023009</b></div>');
            });
        }).on('submit', function(event){
            event.preventDefault();
            // $("#jsFormCotizar,#jsFormCotizar1,#jsFormCotizar2").foundation('resetForm');
        });
    
        /* The above code is making the form validation work. */
        $("#jsContactForm,#jsContactFormDk,#jsContactFormInd,#jsContactFormIndM").on("formvalid.zf.abide", function(ev,form) { 
            // form validation passed, form will submit if submit event not returned false
            console.log("Form id "+form.attr('id')+" is valid");
            // ajax post form
            onContactSubmit(path+'contacto.php',form.attr('id'));
        }).on('submit', function(event){
            event.preventDefault();
            // $("#jsContactForm,#jsContactFormDk,#jsContactFormInd").foundation('resetForm');
        });
    
    
        /**
         * It sends the form data to the server.
         * @param script - The URL to the script that will be called.
         * @param id - The ID of the form.
         */
        const onContactSubmit = (script,id) => {
            let formID = "#"+id;
            let name = $(formID+" #Name").val();
            $.post(script, $(formID).serialize(), function(data){
                console.log({data});
            }).done(function() {
                $("form").html('<div style="font-size: 1.5rem; line-height: 2rem; white-space: break-spaces;" class="help-block label success">Sr/a. <b>'+name+'</b>, el mensaje se envió correctamente. Muchas gracias por contectarnos.</div>');
            }).fail(function() {
                $("form").html('<div style="font-size: 1.5rem; line-height: 2rem; white-space: break-spaces;" class="help-block label error">El mensaje no se pudo enviar. Intente nuevamente o llamenos al <b>+51989023009</b></div>');
            });
        };

        /**
         * Given an array, find the first duplicate element in the array
         * @param arr - an array of integers
         * @returns The first duplicate value in the array.
         */
        const firstDuplicate = (arr) => {
            for(let i = 0; i < arr.length; i++){
               if(arr.lastIndexOf(arr[i]) !== i){
                  return i;
               };
            };
            return -1;
        }


        /**
         * Given a string and a number, return a truncated string with "..." added to the end if the
         * string is longer than the given number
         * @param str - The string to truncate.
         * @param n - The number of characters to truncate to.
         * @returns The truncated string.
         */
        const truncateString = (str, n) => {
            if (str.length > n) {
              return str.substring(0, n) + "...";
            } else {
              return str;
            }
        }

        /**
         * It sends the user's information to the database.
         */
        const formSuccess = () => {
            fbq('track', 'CompleteRegistration');

            $("form").show("fast")
            $(".submit-message").show("fast");
            $(".submit-message").html('<div style="font-size: 1.5rem; line-height: 2rem; white-space: break-spaces;" class="help-block label success">Su cotización fue enviada exitosamente a <b>'+formData.email+'</b>. Muchas gracias por contectarnos. <br><small>Está ventana se cerrara en unos segundos. Gracias.</small></div>');
            setTimeout(() => {
                $(".submit-message").hide("fast");
                setTimeout(() => {
                    $('#cotizarModal').foundation('close');
                }, 2000);
            }, 4000);                        
        }

        /**
         * This function is used to display an error message when the form is submitted and the message
         * is not sent
         */
        const formError = () => {
            $(".submit-message").show("fast")
            $(".submit-message").html('<div style="font-size: 1.5rem; line-height: 2rem; white-space: break-spaces;" class="help-block label error">El mensaje no se pudo enviar, intente nuevamente o llamenos al +51989023009</div>');
            setTimeout(function(){
                $(".submit-message").hide("fast");
                $("form").show("fast");
            }, 4000);        
        }

        const enviroment = (pathname) => {
            
            pathname = pathname.split('.html')[0];

            switch (pathname) {
                case "/":
                    getHome();
                    getFooter();
                    break;
                case "/nosotros":
                    getNosotros();
                    getFooter();
                    break;
                case "/barrios":
                    getBarrios();
                    getFooter();
                    break;
                case "/proyectos":
                    getProyectos();
                    getFooter();
                    break;
                case "/proyecto/":                
                    getProyecto();
                    getFooter();
                    break;
                case "/blog":
                    getBlog();
                    getFooter();
                    break;
                case "/blog/":
                    // getBlogPost();
                    getFooter();
                    break;
                case "/contacto":
                    getContacto();
                    getFooter();
                    break;
            }
        }

        enviroment(location.pathname);

        
    });

}(window.jQuery, window, document));

$(document).foundation();


